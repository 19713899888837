.periodName {
    font-weight: bold;
    margin-bottom: 8px;
}

.periodHeader {
    height: 32px;
}

.temps {
    font-weight: bold;
}

.temps .high {
    color: red;
}

.temps .temp-separator {
    width: 8px;
    display: inline-block;
}

.temps .low {
    color: blue;
}